import {useEffect, useState} from "react";
import {getAll as getRecords} from "app/requests/admin/products";
import {Loading} from "components/helpers";
import Row from "./row";

export default function AdminProductsList() {
  const [records, setRecords] = useState(null);

  useEffect(() => {
    getRecords().then((data) => setRecords(data.records));
  }, []);

  if(!records) { return <Loading />; }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <h1 className="text-center">Products</h1>
          <div className="table-responsive rounded">
            <table className="table table-hover text-center">
              <thead className="thead-dark">
                <tr>
                  <th>Active</th>
                  <th>Image</th>
                  <th>Printful ID</th>
                  <th>Name</th>
                  <th>Rank</th>
                  <th>Removed</th>
                  <th>Created At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {records.map((record) => (
                  <Row key={record.id} record={record} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
