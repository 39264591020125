import PropTypes from "prop-types";
import {displayDate} from "lib/string";

function AdminProductsListRow({record}) {
  return (
    <tr>
      <td>{record.active ? "Yes" : "No"}</td>
      <td>{record.image ? <a href={record.image} rel="noopener noreferrer" target="_blank">View</a> : "None"}</td>
      <td>{record.printful_id}</td>
      <td>{record.name}</td>
      <td>{record.rank}</td>
      <td>{record.removed ? "Yes" : "No"}</td>
      <td>{displayDate(record.created_at)}</td>
      <td>
        <div className="btn-group" role="group" aria-label="Actions">
          <a className="btn btn-sm btn-primary" href={`/admin/products/${record.id}`}>Edit</a>
        </div>
      </td>
    </tr>
  );
}

AdminProductsListRow.propTypes = {
  record: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    created_at: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    image: PropTypes.string,
    name: PropTypes.string.isRequired,
    printful_id: PropTypes.string.isRequired,
    rank: PropTypes.number.isRequired,
    removed: PropTypes.bool.isRequired
  }).isRequired
};

export default AdminProductsListRow;
