import PropTypes from "prop-types";
import File from "components/helpers/form/file";
import FormWithFiles from "components/helpers/form/with-files";

function AdminProductsForm({onChange, onSubmit, value}) {
  return (
    <FormWithFiles onSubmit={onSubmit}>
      <div className="row">
        <div className="col-md-4 offset-md-4">
          <div className="custom-control custom-switch text-center mb-3">
            <input
              checked={value("active", true)}
              className="custom-control-input"
              id="record-active"
              name="active"
              onChange={onChange}
              type="checkbox"
            />
            <label className="custom-control-label" htmlFor="record-active">Active</label>
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="record-rank">Rank</label>
            </div>
            <input
              className="form-control"
              id="record-rank"
              name="rank"
              onChange={onChange}
              placeholder="100"
              required={true}
              type="text"
              value={value("rank")}
            />
          </div>
          <div className="custom-control custom-switch text-center mb-3">
            <input
              checked={value("removed", false)}
              className="custom-control-input"
              disabled={true}
              id="record-removed"
              name="removed"
              type="checkbox"
            />
            <label className="custom-control-label" htmlFor="record-removed">Removed</label>
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="record-name">Name</label>
            </div>
            <input
              className="form-control"
              disabled={true}
              id="record-name"
              name="name"
              placeholder="Name"
              type="text"
              value={value("name")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="record-printful-id">Printful ID</label>
            </div>
            <input
              className="form-control"
              disabled={true}
              id="record-printful-id"
              name="printful_id"
              placeholder="Printful ID"
              type="text"
              value={value("printful_id")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="record-image">Image</label>
            </div>
            <File id="record-image" name="image" onChange={onChange} />
            {value("image") && <img alt="Preview" className="img-fluid" src={value("image")} />}
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="record-data">Data</label>
            </div>
            <textarea
              className="form-control"
              disabled={true}
              id="record-data"
              name="data"
              placeholder="Data"
              rows={10}
              value={JSON.stringify(value("data"), null, 2)}
            />
          </div>
          <div className="form-group text-center">
            <button className="btn btn-primary" type="submit">Submit</button>
          </div>
        </div>
      </div>
    </FormWithFiles>
  );
}

AdminProductsForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.func.isRequired
};

export default AdminProductsForm;
